import React, { Component, createRef } from "react";
import commonFunction from "../../../lib/utils/common";
import configUrl from "../../../../config/config.json";
import { logout } from "../../../../shared/lib/logout";
import Menu from "../../Menu/index";
import {
  setTockenInCoockie,
  getCookieTocken,
  removeUserTokenFromCookie,
} from "../../login/userToken";
import { checksession } from "../../../../shared/lib/checksession";
import Link from "../../../Link";
import chat from "../../../lib/chat";
import UploadLogo from "store/uploadLogo";

// let MenuList=[
//     {name:'My Profile',href:'profile', activeimg:'/images/my-profile-g.svg',inactiveimg:'/images/my-profile-g.svg'},
//     {name:'My Booking',href:'mybooking',activeimg:'/images/my-booking-g.svg',inactiveimg:'/images/my-booking-g.svg'},
//     {name:'Guest & Traveler',href:'guesttraveler', activeimg:'/images/guestTravel-g.svg',inactiveimg:'/images/guestTravel-g.svg/images/prefernce-g.svg'},
//     {name:'Travel Preferences',href:'travelpreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'Communication Preferences',href:'compreferences', activeimg:'/images/prefernce-g.svg',inactiveimg:'/images/prefernce-g.svg'},
//     {name:'My Frequent Flyer',href:'frequentflyer', activeimg:'/images/flyer-g.svg',inactiveimg:'/images/flyer-g.svg'},
//     {name:'Email Notifications',href:'emailnotifications', activeimg:'/images/Email-notifications-g.svg',inactiveimg:'/images/Email-notifications-g.svg'},
//     {name:'Change Password',href:'changepassword', activeimg:'/images/changePwd-g.svg',inactiveimg:'/images/changePwd-g.svg'},
//     {name:'Logout',href:'Logout', activeimg:'/images/Logout-g.svg',inactiveimg:'/images/Logout-g.svg'}
//   ]

let MenuList = [
  {
    Linkindex: 0,
    isPartition: false,
    linkItem: "My Profile",
    isActiveLink: true,
    componentName: "ProfileForm",
    imgInActiveSrc: "/images/my-profile-g.svg",
    imgActiveSrc: "/images/my-profile-g.svg",
    href: "profile",
  },
  {
    Linkindex: 1,
    isPartition: false,
    linkItem: "My Booking",
    isActiveLink: false,
    componentName: "BookingForm",
    imgInActiveSrc: "/images/my-booking-g.svg",
    imgActiveSrc: "/images/my-booking-g.svg",
    href: "mybooking",
  },
  {
    Linkindex: 2,
    isPartition: false,
    linkItem: "Guest & Traveler",
    isActiveLink: false,
    componentName: "GuestTravelerForm",
    imgInActiveSrc: "/images/guestTravel-g.svg",
    imgActiveSrc: "/images/guestTravel-g.svg",
    href: "guesttraveler",
  },
  {
    Linkindex: 3,
    isPartition: false,
    linkItem: "Travel Preferences",
    isActiveLink: false,
    componentName: "TravelPreferencesForm",
    imgInActiveSrc: "/images/prefernce-g.svg",
    imgActiveSrc: "/images/prefernce-g.svg",
    href: "travelpreferences",
  },
  // { Linkindex: 4, isPartition: false, linkItem: 'Communication Preferences', isActiveLink: false, componentName: 'CompreferencesForm', imgInActiveSrc: '/images/prefernce-g.svg', imgActiveSrc: '/images/prefernce-g.svg', href:'compreferences' },
  // { Linkindex: 5, isPartition: false, linkItem:'Coupons & More', isActiveLink: false,  componentName: 'CouponsMoreForm', imgInActiveSrc: '/images/coupon-g.svg', imgActiveSrc: '/images/coupon-g.svg' },
  {
    Linkindex: 6,
    isPartition: false,
    linkItem: "My Frequent Flyer",
    isActiveLink: false,
    componentName: "FrequentFlyerForm",
    imgInActiveSrc: "/images/flyer-g.svg",
    imgActiveSrc: "/images/flyer-g.svg",
    href: "frequentflyer",
  },
  // { Linkindex: 7, isPartition: false, linkItem:'My Wallet', isActiveLink: false,  componentName: 'WalletForm', imgInActiveSrc: '/images/wallet-g.svg', imgActiveSrc: '/images/wallet-g.svg' },
  // { Linkindex: 8, isPartition: false, linkItem:'Payments & More', isActiveLink: false,  componentName: 'PaymentsMoreForm', imgInActiveSrc: '/images/payment-g.svg', imgActiveSrc: '/images/payment-g.svg' },
  // { Linkindex: 9, isPartition: false, linkItem: 'Email notifications', isActiveLink: false, componentName: 'EmailNotificationsForm', imgInActiveSrc: '/images/Email-notifications-g.svg', imgActiveSrc: '/images/Email-notifications-g.svg',href:'emailnotifications' },
  {
    Linkindex: 10,
    isPartition: false,
    linkItem: "Change Password",
    isActiveLink: false,
    componentName: "ChangePasswordForm",
    imgInActiveSrc: "/images/changePwd-g.svg",
    imgActiveSrc: "/images/changePwd-g.svg",
    href: "changepassword",
  },
  {
    Linkindex: 11,
    isPartition: false,
    linkItem: "Logout",
    isActiveLink: false,
    componentName: "Logout",
    imgInActiveSrc: "/images/Logout-g.svg",
    imgActiveSrc: "/images/Logout-g.svg",
    href: "Logout",
  },
];

class MobileHeader1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToken: "",
      isShowPopup: false,
      userFirststname: "",
      userLaststname: "",
      open: false,
      scrolling: false,
    };
    this.supportWrapperRef = createRef();
  }

  setSupportWrapperRef = (node) => {
    this.supportWrapperRef = node;
  };

  handleCalendarClickOutside = (event) => {
    if (
      !this.supportWrapperRef ||
      !this.supportWrapperRef.contains(event.target)
    ) {
      this.setState({ openSupportPopup: false });
    }
  };

  componentDidMount() {
    /* this.setState({
            newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
        }/* , () => {

            this.state.newToken && this.checkSessionApi()
        } )*/
    console.log("this.props.data.user", this.props.data.user);
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleCalendarClickOutside);

    chat(this.props?.data?.siteData?.site?.chat, this.props?.data?.isMobile);
    if (this.props?.data?.user?.status) {
      this.setState({
        userFirststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.first_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        userLaststname: this.props?.data?.user?.first_name
          ? this.props?.data?.user?.last_name.charAt(0)
          : this.props?.data?.user?.email.charAt(0),
        newToken: this.props?.data?.user?.accessToken || "",
      });
    } else {
      this.setState({
        userFirststname: "",
        userLaststname: "",
        newToken: "",
      });
    }

    // console.log("newTokennewTokennewTokennewToken",this.state.newToken)
  }
  checkSessionApi = () => {
    // console.log("checkSessionApicheckSessionApi called")
    // let checksessionreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    // checksession(checksessionreq).then((responseJSON) => {
    //     if (responseJSON.status === true && !commonFunction.isValueEmpty(responseJSON.first_name)) {
    //         let firstChar = responseJSON.first_name.charAt(0)
    //         this.setState({
    //             userFirststname: firstChar
    //         }, () => {
    //         })
    //     } else {
    //     }
    // }).catch(err => {
    //     console.log("error", err)
    // })
  };
  onClickArrow = () => {
    this.setState({
      isShowPopup: !this.state.isShowPopup,
    });
  };
  onMouseLeave = () => {
    // console.log("onMouseLeaveonMouseLeave")
    this.setState({
      isShowPopup: false,
    });
  };

  // componentWillReceiveProps(state, props) {
  //     this.setState({
  //         newToken: getCookieTocken("userToken") !== undefined && getCookieTocken("userToken") !== null && getCookieTocken("userToken") !== "" && getCookieTocken("userToken")
  //     }, () => {
  //         this.state.newToken && this.checkSessionApi()
  //     })
  // }
  onClickProfile = () => {
    commonFunction.redirectTo("/myprofile");
  };
  onClickLogout = () => {
    // let logoutreq = {
    //     "site_id": "6124e1368ede4f08bf2d9ad4",
    //     "accessToken": this.state.newToken
    // }
    logout()
      .then((responseJSON) => {
        if (
          responseJSON.status &&
          responseJSON.message === "Logout successfull"
        ) {
          this.setState({
            newToken: "",
            userFirststname: "",
            userLaststname: "",
          });
          location.reload();
          // commonFunction.redirectTo('/')
          // } else {
          //     this.setState({});
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
    // this.setState({
    // isToken=''
    // })
    // this.props.data.setLoginTokenData=""
  };

  onClickMenu = (bool) => {
    this.setState({ open: bool });
  };

  onClickTab = (id) => {
    // console.log("id=========>", id);
    let newdata = MenuList.map((fact, index) => {
      if (id == index) {
        let data = {
          ...fact,
          active: true,
        };
        return data;
      } else {
        let data = {
          ...fact,
          active: false,
        };
        return data;
      }
    });
    MenuList = newdata;
    this.setState({ open: false });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleCalendarClickOutside);
  };

  handleScroll = (event) => {
    // console.log("clicked");
    if (window.scrollY <= 100 && this.state.scrolling === true) {
      this.setState({ scrolling: false });
      // console.log("false");
    } else if (window.scrollY > 100 && this.state.scrolling !== true) {
      this.setState({ scrolling: true });
      // console.log("true");
    }
  };

  render() {
    console.log("mobile heder=====>", this.props?.data);
    return (
      <header className="mobile-header theme5">
        {/* <div>Mobile Header
            <div className={props.data.cssfile.main_logo_header}>
                <div className={props.data.cssfile.logo_header}>
                <img  className={props.data.cssfile.header_logo} src={`${'/shared/api/media/6124e1368ede4f08bf2d9ad4/'+props.data.headermenu.logo}`} alt="logo" />

                </div>
                </div>

                <div className={styles.logosub_header}>
                    <img className={styles.search_icon} src="../images/searchOption.png" />
                    <img className={styles.call_icon} src="./images/callOption.png" />
                    <img className={styles.login_icon} src="../images/profileOption.png" />

                </div>
            </div> */}

        {this.props.data.pathname === "/myprofile" ? null : (
          <div
            className={`${this.props.data.cssfile.main_logo_header}  ${
              this.state.scrolling === true
                ? this.props.data.cssfile.header_stickyMobile
                : ""
            }`}
          >
            <div className={this.props.data.cssfile.logosub_header}>
              {/* <img className={this.props.data.cssfile.search_icon} src="../images/searchOption.png" /> */}
              {/* {(this.props?.data?.headermenu?.is_show_login == undefined || this.props?.data?.headermenu?.is_show_login) && <a href="tel:+971 58 953 9988"><h2><img className={this.props.data.cssfile.call_icon} src="images/call_-01.svg" alt="Call" /></h2></a>} */}
              {/* <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} /> */}
              {this.props?.data?.headermenu?.is_show_login == undefined ||
              this.props?.data?.headermenu?.is_show_login ? (
                this.state.newToken != undefined &&
                this.state.newToken != null &&
                this.state.newToken != "" ? (
                  <div
                    className={this.props.data.cssfile.afterlogin}
                    onClick={() => {
                      window.location.href = "/myprofile";
                    }}
                  >
                    <span
                      className={this.props.data.cssfile.user_code}
                      onClick={() => {
                        this.onClickMenu(!this.state.open);
                      }}
                    >
                      {this.state.userFirststname}
                      {this.state.userLaststname}
                    </span>
                  </div>
                ) : (
                  <span
                    className={this.props.data.cssfile.login_btn}
                    onClick={this.props.data.onClickHeaderLogin}
                  >
                    {/* <img className={this.props.data.cssfile.login_icon} src="images/user-login-mob1.svg" onClick={this.props.data.onClickHeaderLogin} alt="" /> */}
                    <span onClick={this.props.data.onClickHeaderLogin}>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="200px"
                        height="200px"
                        viewBox="0 0 200 200"
                      >
                        <path
                          fill="#FFFFFF"
                          d="M101,53.479c-18.008,0-32.613,14.604-32.613,32.613c0,18.008,14.604,32.613,32.613,32.613  c18.009,0,32.612-14.604,32.612-32.613C133.612,68.083,119.009,53.479,101,53.479z"
                        ></path>
                        <path
                          fill="#FFFFFF"
                          d="M101,20.863c-45.028,0-81.536,36.505-81.536,81.539c0,45.028,36.508,81.536,81.536,81.536  c45.027,0,81.536-36.508,81.536-81.536C182.536,57.368,146.027,20.863,101,20.863z M35.771,102.401  c0-36.03,29.205-65.232,65.229-65.232c36.023,0,65.226,29.203,65.226,65.232c0,16.536-6.157,31.644-16.303,43.144  c-12.979-11.612-30.132-18.686-48.923-18.686c-18.792,0-35.944,7.073-48.916,18.686C41.932,134.045,35.771,118.944,35.771,102.401z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                )
              ) : (
                []
              )}
              {/* <Menu
                    theme={"theme5"}
                    clickedTab={this.onClickTab}
                    onclickLogout={this.onClickLogout}
                    styles={this.props.data.menucssfile}
                    isopen={this.state.open}
                    MenuList={MenuList}
                    id={"main-menu"}
                    pathname={
                      this.props.data.pathname === "/myprofile"
                        ? new URLSearchParams(window.location.search).get(
                            "Linkindex"
                          ) !== null
                          ? this.props.data.pathname
                          : "myprofile"
                        : this.props.data.pathname
                    }
                    closeMenusHandler={this.onClickMenu}
                  /> */}

              {/* {(this.state.newToken != undefined && this.state.newToken != null && this.state.newToken != "") ?
                              <div className={this.props.data.cssfile.afterlogin}> <span className={this.props.data.cssfile.user_code}>{this.state.userFirststname}</span>
                                  <span onClick={() => { this.onClickArrow() }} >
                                      <img src="https://uat.elevatetrips.com/image/down_arrow.jpg" alt="" />
                                  </span>

                                  <div className={this.state.isShowPopup ? this.props.data.cssfile.show : this.props.data.cssfile.profile_details_hide} onMouseLeave={() => { this.onMouseLeave() }}>
                                      <span onClick={() => { this.onClickProfile() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/myprofile.svg" alt="" />My Profile</span>
                                      <span onClick={() => { this.onClickLogout() }}> <img type="image" src="https://www.intermilesresources.com/sharedresources/img/svg/flyout/links/logout.svg" alt="" />Logout</span>
                                      <span> <img type="image" src="https://uat.elevatetrips.com/image/et-support.png" alt="" />Support</span>

                                  </div>
                              </div> : 
                              <span className={this.props.data.cssfile.login_btn} onClick={this.props.data.onClickHeaderLogin}>
                                  <img className={this.props.data.cssfile.login_icon} src="../images/profileOption.png" onClick={this.props.data.onClickHeaderLogin} />

                              </span>} */}
              {this.state.newToken != undefined &&
                this.state.newToken != null &&
                this.state.newToken != "" && (
                  <p className={this.props.data.cssfile.welcome_text}>
                    {/* Hi  */}
                    {/* {this.props?.data?.user?.first_name} */}
                  </p>
                )}
            </div>
            <div className={this.props.data.cssfile.branchlogomobo}>
              <Link
                title="Logo"
                key={"logo"}
                href={"/"}
                // onclick="window.location.href='/'"
                //onClick={() => { window.location.href = this.props.data.headermenu.supportlink }}
                className=""
              >
                <img
                  height="51"
                  width="81"
                  className={this.props?.data?.cssfile?.header_logo}
                  src={`${
                    "/shared/api/media/" +
                    this.props?.data?.site_id +
                    "/" +
                    (UploadLogo?._currentValue?.isLogo
                      ? this.props?.data?.headermenu?.mobile?.logo
                      : this.props?.data?.headermenu?.mobile?.logo)
                  }`}
                  alt="logo"
                />
              </Link>
            </div>
            <div className={this.props.data.cssfile.logo_header}>
              <ul className={this.props.data.cssfile.navbar_right}>
                <li
                  className={this.props.data.cssfile.dropdown}
                  style={{ display: "none" }}
                >
                  <span className={this.props.data.cssfile.dropdown_span}>
                    <img
                      src="/images/india-flag.png"
                      alt="flag"
                      //    width="20px"
                      //    height="20px"
                    />
                    {/* <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 60 30"
                                width="1200"
                                height="600"
                              >
                                <clipPath id="s">
                                  <path d="M0,0 v30 h60 v-30 z" />
                                </clipPath>
                                <clipPath id="t">
                                  <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
                                </clipPath>
                                <g clipPath="url(#s)">
                                  <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
                                  <path
                                    d="M0,0 L60,30 M60,0 L0,30"
                                    stroke="#fff"
                                    strokeWidth="6"
                                  />
                                  <path
                                    d="M0,0 L60,30 M60,0 L0,30"
                                    clipPath="url(#t)"
                                    stroke="#C8102E"
                                    strokeWidth="4"
                                  />
                                  <path
                                    d="M30,0 v30 M0,15 h60"
                                    stroke="#fff"
                                    strokeWidth="10"
                                  />
                                  <path
                                    d="M30,0 v30 M0,15 h60"
                                    stroke="#C8102E"
                                    strokeWidth="6"
                                  />
                                </g>
                              </svg> */}
                    <div className={this.props.data.cssfile.znth_main}>
                      <span>IN</span>
                      <span className={this.props.data.cssfile.divider_line}>
                        |
                      </span>
                      <span>EN</span>
                      <span className={this.props.data.cssfile.divider_line}>
                        |
                      </span>
                      <span>INR</span>
                      {/* <span className={this.props.data.cssfile.drop_arrow}>
                            ▾
                          </span> */}
                      {/* <span
                                ref={this.setCurrencyWrapperRef}
                                onClick={() => {this.setState({ openCurrencyPopup: true });}} 
                                className={this.props.data.cssfile.dropZ_arrow}>▾</span> */}
                    </div>
                    {this.state.openCurrencyPopup && (
                      <div
                        className={this.props.data.cssfile.language_dropMain}
                        style={{ display: "block" }}
                      >
                        <div
                          className={this.props.data.cssfile.language_mnCont}
                        >
                          <div
                            className={this.props.data.cssfile.language_wrap}
                          >
                            <div className={this.props.data.cssfile.lang_title}>
                              {" "}
                              Select Language
                            </div>
                            <input
                              type="radio"
                              id="english"
                              name="language"
                              value="english"
                              checked
                            />
                            <label
                              className={this.props.data.cssfile.lang_label}
                              for="english"
                            >
                              English
                            </label>
                          </div>
                          <div
                            className={this.props.data.cssfile.language_wrap}
                          >
                            <div className={this.props.data.cssfile.lang_title}>
                              {" "}
                              Select Currency
                            </div>
                            <input
                              type="radio"
                              id="currency"
                              name="currency"
                              value="currency"
                              checked
                            />
                            <label
                              className={this.props.data.cssfile.lang_label}
                              for="english"
                            >
                              INR
                            </label>
                          </div>
                        </div>
                        <div className={this.props.data.cssfile.apply_btn}>
                          <button>Apply</button>
                        </div>
                      </div>
                    )}
                    {/* <img src="/theme2/down_arrow.png" className={this.props.data.cssfile.drop_arrow} alt=""/> */}
                  </span>

                  {/* Language currency dropdown */}
                  <div
                    className={this.props.data.cssfile.dropdown_menu_wrap}
                    style={{ display: "none" }}
                  >
                    <div className={this.props.data.cssfile.language_drop}>
                      <div className={this.props.data.cssfile.language_label}>
                        Language
                      </div>
                      <span
                        className={this.props.data.cssfile.nation_title_wrap}
                      >
                        <img
                          src=""
                          className={this.props.data.cssfile.nation_flag}
                          alt="nation flag"
                        />
                        <span className={this.props.data.cssfile.nation_label}>
                          India
                        </span>
                      </span>

                      <div
                        className={
                          this.props.data.cssfile.country_drop_container
                        }
                        style={{ display: "none" }}
                      >
                        <div
                          className={this.props.data.cssfile.country_scroll_box}
                        >
                          <div
                            className={
                              this.props.data.cssfile.country_dtail_wrap
                            }
                          >
                            <ul>
                              <li
                                className={this.props.data.cssfile.country_name}
                              >
                                India
                              </li>
                              <li
                                className={this.props.data.cssfile.country_name}
                              >
                                UAE
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={this.props.data.cssfile.language_drop}>
                      <div className={this.props.data.cssfile.language_label}>
                        Currency
                      </div>
                      <span
                        className={this.props.data.cssfile.nation_title_wrap}
                      >
                        <img
                          src=""
                          className={this.props.data.cssfile.nation_flag}
                          alt="nation flag"
                        />
                        <span className={this.props.data.cssfile.nation_label}>
                          India
                        </span>
                      </span>

                      <div
                        className={
                          this.props.data.cssfile.country_drop_container
                        }
                        style={{ display: "none" }}
                      >
                        <div
                          className={this.props.data.cssfile.country_scroll_box}
                        >
                          <div
                            className={
                              this.props.data.cssfile.country_dtail_wrap
                            }
                          >
                            <ul>
                              <li
                                className={this.props.data.cssfile.country_name}
                              >
                                AED
                              </li>
                              <li
                                className={this.props.data.cssfile.country_name}
                              >
                                USD
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Language currency dropdown */}
                </li>
                <li className={this.props.data.cssfile.support}>
                  <div
                    className={this.props.data.cssfile.suport_cont}
                    ref={this.setSupportWrapperRef}
                  >
                    <div
                      className={this.props.data.cssfile.znth_support}
                      onClick={() => {
                        this.setState({
                          openSupportPopup: !this.state.openSupportPopup,
                        });
                      }}
                    >
                      {/* <span>Support</span> */}
                      <span className={this.props.data.cssfile.dropZ_arrow}>
                        <svg
                          version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="26.75px"
                          height="26.75px"
                          viewBox="0 0 26.75 26.75"
                        >
                          <g>
                            <path
                              fill="#FFFFFF"
                              d="M15.711,13.257c-0.538,0.747-1.286,1.306-2.334,1.306c-3.201,0-3.644-5.16-3.646-5.183V9.094
		c0.438-0.259,1.067-0.737,1.574-1.345c1.343,1.224,4.473,1.449,5.717,1.491l0.001,0.111c-0.002,0.025-0.106,1.198-0.554,2.427
		c0.676-0.081,1.377-0.196,1.968-0.364c-0.206-1.773-0.435-3.455-0.438-3.481c-0.002-0.021-0.013-0.039-0.02-0.058
		c0.032-0.463,0.028-0.89-0.016-1.172c-0.465-3.069-4.558-3-4.581-3.003C13.346,3.695,9.252,3.64,8.788,6.703
		C8.744,6.984,8.741,7.411,8.772,7.875C8.765,7.894,8.754,7.912,8.751,7.933c-0.006,0.044-0.597,4.39-0.729,6.491
		c-0.006,0.1,0.042,0.195,0.125,0.252c0.047,0.031,0.103,0.047,0.155,0.047c0.044,0,0.086-0.01,0.125-0.028
		c0.012-0.008,1.043-0.512,2.017-0.93c0.653,0.889,1.592,1.56,2.933,1.56c1.34,0,2.277-0.671,2.931-1.561
		c0.972,0.419,2.004,0.923,2.016,0.931c0.039,0.019,0.083,0.028,0.124,0.028c0.055,0,0.108-0.016,0.156-0.047
		c0.084-0.057,0.132-0.151,0.126-0.252c-0.029-0.456-0.08-1.02-0.142-1.621C17.753,13.042,16.689,13.18,15.711,13.257z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M20.009,8.897c-0.137-0.065-0.288-0.086-0.445-0.087c0.001-0.03,0.004-0.062,0.004-0.092
		c0-3.375-1.053-6.225-6.191-6.225c-5.14,0-6.191,2.85-6.191,6.225c0,0.03,0.003,0.062,0.003,0.092
		C7.03,8.811,6.88,8.832,6.743,8.897c-0.584,0.279-1.06,1.42-0.5,2.619c0.391,0.834,1.171,0.705,1.619,0.543
		c0.031-0.287,0.065-0.573,0.099-0.858c-0.132-0.585-0.079-1.107,0.011-1.494l0.004-0.001c0-0.004-0.001-0.008-0.002-0.012
		C8.07,9.287,8.203,9.026,8.203,9.026C8.099,8.991,7.999,8.959,7.9,8.93C7.898,8.858,7.892,8.789,7.892,8.718
		c0-3.124,0.898-5.533,5.484-5.533c4.587,0,5.483,2.409,5.483,5.533c0,0.07-0.006,0.14-0.009,0.211
		c-0.099,0.03-0.199,0.061-0.301,0.097c0,0,0.132,0.26,0.228,0.667c0,0.005,0,0.008,0,0.015h0.004
		c0.114,0.494,0.166,1.211-0.148,1.996c-1.076,0.4-3.186,0.554-4.516,0.598c-0.169-0.158-0.432-0.266-0.74-0.266
		c-0.516,0-0.933,0.282-0.933,0.627c0,0.346,0.417,0.627,0.933,0.627c0.329,0,0.605-0.124,0.771-0.297
		c1.334-0.044,4.023-0.211,5.145-0.832c0.425,0.061,0.927-0.027,1.215-0.646C21.066,10.316,20.594,9.175,20.009,8.897z"
                            />
                            <path
                              fill="#FFFFFF"
                              d="M21.65,22.043c-0.577-1.974-1.057-4.33-1.925-5.195c-0.865-0.867-2.934-1.926-2.934-1.926
		c0.006,0.495-0.063,0.961-0.176,1.402c-1.866,1.573-4.614,1.574-6.481,0c-0.113-0.442-0.183-0.907-0.175-1.402
		c0,0-2.07,1.059-2.936,1.926c-0.865,0.865-1.348,3.222-1.925,5.195c-0.578,1.972,0.338,2.212,0.961,2.212h7.315h7.313
		C21.313,24.255,22.229,24.015,21.65,22.043z M13.377,20.634c0,0-1.667-1.063-2.672-2.884c0.836,0.424,1.752,0.639,2.67,0.639
		s1.835-0.216,2.672-0.639C15.042,19.571,13.377,20.634,13.377,20.634z M19.027,21.319h-3.082v-1.075h3.082V21.319z"
                            />
                          </g>
                        </svg>{" "}
                        ▾
                      </span>
                    </div>
                    {this.state.openSupportPopup && (
                      <div
                        className={this.props.data.cssfile.supportdropNew}
                        style={{ display: "block" }}
                      >
                        <a
                          href={
                            localStorage.getItem("appType") == "mobileapp"
                              ? "/mobileAppLink?page=supportNo&value=" +
                                this.props?.data?.siteData?.site?.support_number
                              : `tel:${this.props?.data?.siteData?.site?.support_number}`
                          }
                        >
                          <div
                            className={this.props.data.cssfile.header_menuZth}
                          >
                            {/* <img src="/images/et-hd-phn.png" alt="" /> */}
                            {/* mobile icon */}
                            <svg
                              fill="currentColor"
                              height="20px"
                              width="20px"
                              version="1.1"
                              id="Capa_1"
                              viewBox="0 0 473.806 473.806"
                            >
                              <g>
                                <g>
                                  <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4    c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8    c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6    c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5    c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26    c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9    c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806    C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20    c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6    c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1    c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3    c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5    c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8    c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9    l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1    C420.456,377.706,420.456,388.206,410.256,398.806z"></path>
                                  <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2    c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11    S248.656,111.506,256.056,112.706z"></path>
                                  <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11    c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2    c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"></path>
                                </g>
                              </g>
                            </svg>
                            {this.props?.data?.siteData?.site?.support_number}
                          </div>
                        </a>
                        <a
                          href={
                            localStorage.getItem("appType") == "mobileapp"
                              ? "/mobileAppLink?page=supportEmail&value=" +
                                this.props?.data?.siteData?.site?.support_email
                              : `mailto:${this.props?.data?.siteData?.site?.support_email}`
                          }
                        >
                          <div
                            className={this.props.data.cssfile.header_menuZth}
                          >
                            {/* <img
                              src="/images/et-hd-email.png"
                              alt=""
                              width="20px"
                              height="20px"
                            /> */}
                            <svg
                              height="20px"
                              width="20px"
                              version="1.1"
                              id="_x32_"
                              viewBox="0 0 512 512"
                              fill="currentColor"
                            >
                              <g>
                                <path
                                  class="st0"
                                  d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345   c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345   C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203   c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958   c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064   c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571   c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637   L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136   C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435   C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959   l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959   C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8   V385.92z"
                                ></path>
                              </g>
                            </svg>
                            {this.props?.data?.siteData?.site?.support_email}
                          </div>
                        </a>
                      </div>
                    )}
                  </div>
                </li>

                <li className={this.props.data.cssfile.dropdown}>
                  {/* <a href="#"> 
                              <img src="/images/profile-01.svg" className={this.props.data.cssfile.profile_icon} />
                              <span> My Account</span> 
                              <img src="/images/down_arrow.jpg" className={this.props.data.cssfile.drop_arrow} />
                            </a>  */}

                  {/* Login signup dropdown old */}
                  <div
                    className={this.props.data.cssfile.account_container}
                    style={{ display: "none" }}
                  >
                    <ul className={this.props.data.cssfile.drop_my_account}>
                      <li className={this.props.data.cssfile.login}>
                        <a href="#" title="Login">
                          <span>
                            {/* <img src="/images/profile-01.svg" className={this.props.cssfile.user_icon} /> */}
                            Login
                          </span>
                        </a>
                      </li>
                      <li className={this.props.data.cssfile.signup}>
                        <a href="#" title="Sign Up">
                          <span>
                            {/* <img src="/images/profile-01.svg" className={this.props.cssfile.user_icon} /> */}
                            Sign Up
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  {/* Login signup dropdown */}
                </li>
              </ul>
            </div>
          </div>
        )}
      </header>
    );
  }
}

export default MobileHeader1;
