import React, { Component, createRef } from "react";

import assistedBookingAgent from "../../../lib/assistedBookingAgent";
import assistedNewToken from "../../../lib/assistedNewToken";
// import { checksession } from "../../../lib/checksession";
import { withRouter } from "next/router";
import assistedBooking from "../../../lib/assistedBooking";
// import config from '../../../../config/config.json'
let agentOptions = [];
class Theme4 extends Component {
  dropdownRef = createRef();
  state = {
    selectedSubBranch: "",
    selectedAgency: "",
    selectedSubAgency: "",
    filteredSubagencies: [],
    retrievedAgents: [],
    selectedAgent: "",
    selectedAgentName: "",
    site_id: "",
    channels: [],
    selectedChannel: "",
    showDropdown: false,
    loading: false,
    noagents: false,
    selectedcorporate: "",
    selectedwhitelabel: "",
    selectedbranch: "",
    selectedsubcompany: "",
    selectedcompany: "",
    filteredCorporate: [],
    filteredAgency: [],
    filteredCompany: [],
    filteredsubCompany: [],
    filteredwhitelabel: [],
    filteredsubBranch: [],
    selectRadio: [
      { label: "Booking Agent", select: true, key: 1 },
      { label: "Direct Agent", select: false, key: 2 },
      { label: "Corporate Admin/TD", select: true, key: 3 },
      { label: "Corporate Traveller", select: false, key: 4 },
    ],
    is_admin: true,
    BOOKING_AGENT: true,
    DIRECT_AGENT: false,
    B2E_ADMIN_TD: true,
    B2E_TRAVELLER: false,
    okloading: false,
    site_id_error: false,
  };

  componentDidMount() {
    const channels = this.props?.data?.data?.org?.childs;
    const organizations = this.props?.data?.data?.org;
    // console.log("organizations*********",organizations);
    const channelItems = Object.keys(channels);
    let channelArray = [];
    if (channelItems.length > 0) {
      channelItems.map((item) => {
        channelArray.push(item);
      });
      channelArray.push("Agent");
    } else if (organizations?.type?.toLowerCase() !== "corporate") {
      channelArray.push("Agent");
    } else {
      channelArray.push("no result found");
    }
    // console.log("channelArray***********",channelArray);
    this.setState({ channels: channelArray });
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.state.showDropdown &&
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(e.target)
    ) {
      this.setState({ showDropdown: false });
    }
  };

  handleChannelChange = async (e) => {
    if (e.target.value === "Agent") {
      this.setState({ loading: true, selectedChannel: "Agent" });
      const data = await assistedBookingAgent(
        this.props?.data?.data?.org?._id || ""
      );
      const { agents } = data.org;
      this.setState({
        selectedChannel: "Agent",
        retrievedAgents: agents,
        loading: false,
        filteredCorporate: [],
        filteredAgency: [],
        filteredCompany: [],
        filteredsubCompany: [],
        filteredwhitelabel: [],
        filteredsubBranch: [],
      });
      if (agentOptions.length === 0) {
        this.setState({ noagents: true, loading: false }, () => {
          // Any logic you want to execute after state is updated
          console.log("No agents state updated:", this.state.noagents);
        });
      }
    } else {
      this.setState({
        selectedChannel: e.target.value,
        retrievedAgents: [],
        filteredSubagencies: [],
        selectedSubAgency: "",
        selectedAgency: "",
        selectedSubBranch: "",
        selectedAgentName: "",
        selectedcorporate: "",
        selectedwhitelabel: "",
        selectedbranch: "",
        selectedsubcompany: "",
        selectedcompany: "",
        showDropdown: false,
        filteredCorporate: [],
        filteredAgency: [],
        filteredCompany: [],
        filteredsubCompany: [],
        filteredwhitelabel: [],
        filteredsubBranch: [],
      });
    }
    this.setState({ selectedAgent: "",
      BOOKING_AGENT: true,
      DIRECT_AGENT: false,
      B2E_ADMIN_TD: true,
      B2E_TRAVELLER: false,
     });
  };

  handleSubBranchChange = async (e) => {
    const selectedsubbranchid = e.target.value;
    this.setState({
      selectedSubBranch:
        e.target.value === this.state.selectedSubBranch
          ? ""
          : selectedsubbranchid,
    });
    // const data = await assistedBooking(selectedsubbranchid);
    // const { childs } = data.data.org;
    // if(Object.values(childs).length === 0) {

    const data = await assistedBookingAgent(selectedsubbranchid);
    const { agents } = data.org;
    let newFilterOption = agents.filter((item) => {
      return item.is_admin === this.state.is_admin;
    });
    // console.log("p---------------------",newFilterOption);
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
    // }
    // else {
    //   this.setState({ selectedSubBranch: selectedsubbranchid });
    // }
  };

  handlecorporateChange = async (e) => {
    const selectedcorporateid = e.target.value;
    // this.setState({ selectedAgency: 'Agent', loading: true });
    console.log(selectedcorporateid, "selectedcorporateid");
    this.setState({
      selectedcorporate:
        e.target.value === this.state.selectedcorporate
          ? ""
          : selectedcorporateid,
    });

    const data = await assistedBookingAgent(selectedcorporateid);
    console.log(data, "data");
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }

    // const { agents } = data.org;
    // this.setState({  retrievedAgents: agents, loading: false });
    // if(agents.length == 0) {
    //   this.setState({ noagents: true, loading: false });
    // }
  };
  handlecompanyChange = async (e) => {
    const selectedcompanyid = e.target.value;

    this.setState({
      selectedcompany:
        e.target.value === this.state.selectedcompany ? "" : selectedcompanyid,
    });

    const data = await assistedBookingAgent(selectedcompanyid);
    // console.log(data,'comapany');
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
  };
  handleWhitelabelChange = async (e) => {
    const selectedwhitelabelid = e.target.value;
    this.setState({
      selectedwhitelabel:
        e.target.value === this.state.selectedwhitelabel
          ? ""
          : selectedwhitelabelid,
    });

    const data = await assistedBookingAgent(selectedwhitelabelid);
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
  };
  handleSubCompanyChange = async (e) => {
    const selectedsubcompanyid = e.target.value;
    this.setState({
      selectedsubcompany:
        e.target.value === this.state.selectedsubcompany
          ? ""
          : selectedsubcompanyid,
    });

    const data = await assistedBookingAgent(selectedsubcompanyid);
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
  };
  handlebranchChange = async (e) => {
    const selectedbranchid = e.target.value;
    this.setState({
      selectedbranch:
        e.target.value === this.state.selectedbranch ? "" : selectedbranchid,
      // loading: true,
    });

    const data = await assistedBookingAgent(selectedbranchid);
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    console.log("agents", agents);
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false }, () => {
        // Any logic you want to execute after state is updated
        console.log("No agents state updated:", this.state.noagents);
      });
    }
  };

  handleAgencyChange = async (e) => {
    const selectedAgencyId = e.target.value;
    // this.setState({ selectedAgency: "Agent", loading: true });
    this.setState({
      selectedAgency:
        e.target.value === this.state.selectedAgency ? "" : selectedAgencyId,
    });

    const data = await assistedBookingAgent(
      selectedAgencyId === "Agent"
        ? this.state.selectedAgency
        : selectedAgencyId
    );
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
    // if(selectedAgencyId === 'Agent') {
    // }
    // else {
    // this.setState({ loading: true });
    // const data = await assistedBooking(selectedAgencyId);
    // const { childs } = data.data.org;
    // if(Object.values(childs).length === 0) {
    // this.setState({ selectedAgency: 'Agent', loading: true });
    // const data = await assistedBookingAgent(selectedAgencyId);
    // const { agents } = data.org;
    // this.setState({  retrievedAgents: agents, loading: false });
    // if(agents.length == 0) {
    //   this.setState({ noagents: true, loading: false });
    // }
    // }
    // else {
    //   this.setState({ loading: false });
    //     const filteredSubagencies =
    //     this.props?.data?.data?.org?.childs?.subagency?.filter(
    //       (sub) => sub.parent_id === selectedAgencyId
    //     );
    //   this.setState({ retrievedAgents: [], selectedAgency: selectedAgencyId, filteredSubagencies });
    // }
    // }
  };

  handleSubAgencyChange = async (e) => {
    const selectedSubAgencyId = e.target.value;
    this.setState({
      selectedSubAgency:
        e.target.value === this.state.selectedSubAgency
          ? ""
          : selectedSubAgencyId,
      loading: true,
    });

    const data = await assistedBookingAgent(selectedSubAgencyId);
    const { agents } = data.org;
    this.setState({ retrievedAgents: agents, loading: false });
    this.setState({ noagents: true, loading: false }, () => {
      // Any logic you want to execute after state is updated
      console.log("No agents state updated:", this.state.noagents);
    });
    if (agents.length == 0) {
      this.setState({ noagents: true, loading: false });
    }
  };

  handleAgentChange = (e) => {
    console.log("Agent changed", agentOptions);
    // const siteId = e.target.options[e.target.selectedIndex].getAttribute("id");
    this.setState({ selectedAgentName: e.target.value, showDropdown: true });
  };

  onUserInputClicked = (name, id) => {
    this.setState({
      selectedAgent: id,
      selectedAgentName: name,
      showDropdown: false,
    });
  };

  submitButton = async () => {
    this.setState({
      okloading: true,
    });
    const { selectedAgent, retrievedAgents } = this.state;
    const reqBody = {
      user_id: selectedAgent,
      assit_login: true,
      site_id: retrievedAgents[0]?.site_id || "",
    };
    const data = await assistedNewToken(reqBody);
    const { accessToken } = data;
    if (accessToken) {
      this.setState({
        okloading: false,
      });
      this.setState({
        site_id_error: false,
      });
      window.location.href = data?.site_url ? data?.site_url : "/";
    } else {
      this.setState({
        site_id_error: true,
      });
    }
  };

  onClickRadio = (id) => {
    this.setState({
      selectedAgentName: "",
    });
    let newdata = this.state.selectRadio.map((res, ind) => {

      //       "dropdown_agent": "" is added plz use “dropdown_agent”  key to bind in FRONTEND.

      // if   "dropdown_agent": "B2E_ADMIN_TD" ==>  Corporate admin/TD.

      // if     "dropdown_agent": "B2E_TRAVELLER" ==> Corporate Traveller.

      // if "dropdown_agent": "BOOKING_AGENT" ==> Booking Agent.

      // if "dropdown_agent": "DIRECT_AGENT" ==> Direct Agent.

      if (id === 1) {
        this.setState({
          BOOKING_AGENT: true,
          DIRECT_AGENT: false,
        });
      } else if (id === 2) {
        this.setState({
          BOOKING_AGENT: false,
          DIRECT_AGENT: true,
        });
      } else if (id === 3) {
        this.setState({
          B2E_ADMIN_TD: true,
          B2E_TRAVELLER: false,
        });
      } else if (id === 4) {
        this.setState({
          B2E_ADMIN_TD: false,
          B2E_TRAVELLER: true,
        });
      } else {
        this.setState({
          BOOKING_AGENT: true,
          DIRECT_AGENT: false,
          B2E_ADMIN_TD: true,
          B2E_TRAVELLER: false,
        });
      }

      // if (id === 1) {
      //   this.setState({
      //     is_admin: true,

      //   })
      // } else {
      //   this.setState({
      //     is_admin: false
      //   })
      // }
      if (res.key === id) {
        return {
          ...res,
          select: true,
        };
      } else {
        return {
          ...res,
          select: false,
        };
      }
    });

    this.setState({
      selectRadio: newdata,
    });
  };

  render() {
    const {
      selectedChannel,
      selectedSubBranch,
      selectedAgency,
      selectedSubAgency,
      filteredSubagencies,
      retrievedAgents,
      selectedAgentName,
      showDropdown,
      loading,
      noagents,
      selectedcorporate,
      selectedwhitelabel,
      selectedbranch,
      selectedsubcompany,
      selectedcompany,
      filteredAgency,
      filteredCorporate,
      filteredCompany,
      filteredsubCompany,
      filteredwhitelabel,
      filteredsubBranch,
    } = this.state;
    const { styles, data } = this.props;
    const channelOptions = this.state.channels.map((org, index) => (
      <option
        key={index}
        value={org}
        disabled={org?.toLowerCase() === "no result found" ? true : false}
      >
        {org}
      </option>
    ));

    const subAgencies = data?.data?.org?.childs?.subagency?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );

    const subBranches = data?.data?.org?.childs?.subbranch?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );

    const agencyOptions = data?.data?.org?.childs?.agency?.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));

    const corporateOptions = data?.data?.org?.childs?.corporate?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );
    const branchOptions = data?.data?.org?.childs?.branch?.map((org, index) => (
      <option key={index} value={org._id}>
        {org.org_name}
      </option>
    ));
    const subranchOptions = data?.data?.org?.childs?.subbranch?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );
    const subcompanyOptions = data?.data?.org?.childs?.subcompany?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );
    const companyOptions = data?.data?.org?.childs?.company?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );
    const whitelabelOptions = data?.data?.org?.childs?.whitelabel?.map(
      (org, index) => (
        <option key={index} value={org._id}>
          {org.org_name}
        </option>
      )
    );

    // const agentOptions = retrievedAgents
    //   .filter((item) => {
    //     const searchItem = selectedAgentName?.toLocaleLowerCase();
    //     const name = item?.first_name?.toLowerCase();
    //     const emailId = item?.email?.toLocaleLowerCase();
    //     return (
    //       (name?.includes(searchItem) || emailId?.includes(searchItem)) &&
    //       name !== searchItem &&
    //       this.props?.user?._id !== item?._id
    //     );
    //   })
    //   .slice(0, 4)
    //   .map((agent, index) => {
    //     return (
    //       <div
    //         key={index}
    //         className={styles.users}
    //         onClick={() => this.onUserInputClicked(agent.first_name, agent._id)}
    //       >
    //         {agent.first_name}
    //       </div>
    //     );
    //   });
    let newretrievedAgents = retrievedAgents.filter(
      (res) => res._id != this.props?.user?._id
    );
    let checkFname = newretrievedAgents.filter((item) =>
      item.hasOwnProperty("first_name")
    );
    agentOptions = checkFname
      .filter((item) => {
        const searchItem = selectedAgentName?.toLocaleLowerCase();
        const name = item?.first_name?.toLowerCase();
        console.log("selectedAgentName", selectedAgentName);

        if (this.state.B2E_ADMIN_TD && item?.dropdown_agent == "B2E_ADMIN_TD") {
          return (
            name?.includes(searchItem.toLowerCase()) &&
            this.props?.user?._id !== item?._id
          );
        }
        if (
          this.state.B2E_TRAVELLER &&
          item?.dropdown_agent == "B2E_TRAVELLER"
        ) {
          return (
            name?.includes(searchItem.toLowerCase()) &&
            this.props?.user?._id !== item?._id
          );
        }
        if (
          this.state.BOOKING_AGENT &&
          item?.dropdown_agent == "BOOKING_AGENT"
        ) {
          return (
            name?.includes(searchItem.toLowerCase()) &&
            this.props?.user?._id !== item?._id
          );
        }
        if (this.state.DIRECT_AGENT && item?.dropdown_agent == "DIRECT_AGENT") {
          return (
            name?.includes(searchItem.toLowerCase()) &&
            this.props?.user?._id !== item?._id
          );
        }
        // return false; // Return false if no conditions match
      })
      .map((agent, index) => {
        return (
          <div
            key={index}
            className={styles.users}
            onClick={() =>
              this.onUserInputClicked(agent?.first_name, agent?._id)
            }
          >
            {agent.first_name} {agent.last_name} <br />
            <span>Email: {agent?.email}</span>
          </div>
        );
      });

    let text = "";

    switch (true) {
      case this.state.BOOKING_AGENT &&
        this.state.selectRadio[0].select &&
        (selectedChannel !== "corporate"&&selectedChannel !== "company"):
        text = "Booking Agent";
        break;
      case this.state.DIRECT_AGENT &&
        this.state.selectRadio[1].select &&
        (selectedChannel !== "corporate"&&selectedChannel !== "company"):
        text = "Direct Agent";
        break;
      case this.state.B2E_ADMIN_TD &&
        this.state.selectRadio[2].select &&
        (selectedChannel === "corporate"||selectedChannel === "company"):
        text = "Corporate Admin";
        break;
      case this.state.B2E_TRAVELLER &&
        this.state.selectRadio[3].select &&
        (selectedChannel === "corporate"||selectedChannel === "company"):
        text = "Corporate Traveller";
        break;
      default:
        text = "";
    }

    return (
      <div className={styles.modal_overlay}>
        <div className={styles.modal}>
          <div className={styles.crossbutton}>
            <svg
              onClick={() => {
                this.setState({ showDropdown: false });
                this.props.setAssistedBokingPopup(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className={styles.assist_book_cont}>
            <h2 className={styles.agnc_head}>Select Channel</h2>
            <select
              className={styles.select_bx}
              value={selectedChannel}
              onChange={this.handleChannelChange}
            >
              <option value="">Select</option>
              {channelOptions}
            </select>

            {selectedChannel === "branch" && (
              <>
                <h2 className={styles.agnc_head}>Select Branch:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedbranch}
                  onChange={this.handlebranchChange}
                >
                  <option value="">Select</option>
                  {branchOptions}
                </select>
              </>
            )}

            {selectedChannel === "subbranch" && (
              <>
                <h2 className={styles.agnc_head}>Select Sub Branch:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedSubBranch}
                  onChange={this.handleSubBranchChange}
                >
                  <option value="">Select</option>
                  {subBranches}
                </select>
              </>
            )}

            {selectedChannel === "corporate" && (
              <>
                <h2 className={styles.agnc_head}>Select corporate:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedcorporate}
                  onChange={this.handlecorporateChange}
                >
                  <option value="">Select</option>
                  {corporateOptions}
                </select>
              </>
            )}
            {selectedChannel === "whitelabel" && (
              <>
                <h2 className={styles.agnc_head}>Select whitelabel:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedwhitelabel}
                  onChange={this.handleWhitelabelChange}
                >
                  <option value="">Select</option>
                  {whitelabelOptions}
                </select>
              </>
            )}

            {selectedChannel === "company" && (
              <>
                <h2 className={styles.agnc_head}>Select Company:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedcompany}
                  onChange={this.handlecompanyChange}
                >
                  <option value="">Select</option>
                  {companyOptions}
                </select>
              </>
            )}
            {selectedChannel === "subcompany" && (
              <>
                <h2 className={styles.agnc_head}>Select Sub company:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedsubcompany}
                  onChange={this.handleSubCompanyChange}
                >
                  <option value="">Select</option>
                  {subcompanyOptions}
                </select>
              </>
            )}

            {selectedChannel === "agency" && (
              <>
                <h2 className={styles.agnc_head}>Select Agency:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedAgency}
                  onChange={this.handleAgencyChange}
                >
                  <option value="">Select</option>
                  {agencyOptions}
                  {/* {selectedChannel === "subbranch" && (
                      <option value={"Agent"}>Agents</option>
                    )} */}
                </select>

                {/* {  selectedAgency === "Agent" ||
                  selectedAgency === "" ? null : (
                    <>
                      <h2 className={styles.agnc_head}> Select Subagencies:</h2>
                      <select
                        className={styles.select_bx}
                        value={selectedSubAgency}
                        onChange={this.handleSubAgencyChange}
                      >
                        <option value="">Select</option>
                        {subAgencyOptions}
                        {(selectedChannel === "agency" ||
                          selectedChannel === "subbranch") && (
                          <option value={selectedSubAgency}>Agent</option>
                        )}
                      </select>
                    </>
                  )} */}
              </>
            )}

            {selectedChannel === "subagency" && retrievedAgents.length == 0 && (
              <>
                <h2 className={styles.agnc_head}>Select Sub Agency:</h2>
                <select
                  className={styles.select_bx}
                  value={selectedSubAgency}
                  onChange={this.handleSubAgencyChange}
                >
                  <option value="">Select</option>
                  {subAgencies}
                </select>
              </>
            )}

            {loading && <div className={styles.login_loader} />}
            {/* {(retrievedAgents.length > 0 || selectedChannel === "Agent") &&
            !loading ? (
              <>
                <h2 className={styles.agnc_head}>Agents:</h2>
                <input
                  type="text"
                  placeholder="Select an Agent"
                  className={styles.select_bx}
                  value={selectedAgentName}
                  onChange={this.handleAgentChange}
                  onClick={() => {
                    this.setState({ showDropdown: true });
                  }}
                />
                {showDropdown && (
                  <div className={styles.dropdown} ref={this.dropdownRef}>
                    {agentOptions}
                  </div>
                )}
                <button className={styles.ok_btn} onClick={this.submitButton}>
                  Ok
                </button>
              </>
            ) : (
              <>
                {noagents && (
                  <p className={styles.subagncy_label}>No Agents found</p>
                )}
              </>
            )} */}
            {selectedChannel != "" &&
              (selectedChannel === "corporate"||selectedChannel === "company") &&
              this.state.selectRadio.map((res) => {
                if (res?.key === 3 || res?.key === 4) {
                  return (
                    <div className={styles.inp_flex} key={res?.key}>
                      <input
                        type="radio"
                        onChange={() => this.onClickRadio(res?.key)}
                        checked={res?.select}
                      />
                      <span>{res?.label}</span>
                    </div>
                  );
                } else {
                  return null; // Render nothing for other keys
                }
              })}
            {selectedChannel != "" &&
              selectedChannel !== "corporate"&&selectedChannel !== "company"&&
              this.state.selectRadio.map((res) => {
                if (res?.key !== 3 && res?.key !== 4) {
                  return (
                    <div className={styles.inp_flex} key={res?.key}>
                      <input
                        type="radio"
                        onChange={() => this.onClickRadio(res?.key)}
                        checked={res?.select}
                      />
                      <span>{res?.label}</span>
                    </div>
                  );
                } else {
                  return null; // Render nothing for keys 3 and 4
                }
              })}

            {agentOptions?.length > 0 ? (
              !loading ? (
                <>
                  <h2 className={styles.agnc_head}> {text} :</h2>
                  <input
                    type="text"
                    placeholder={text}
                    className={styles.select_bx_new}
                    value={selectedAgentName}
                    onChange={this.handleAgentChange}
                    onClick={() => {
                      this.setState({ showDropdown: true });
                    }}
                  />
                  {showDropdown && (
                    <div className={styles.dropdown} ref={this.dropdownRef}>
                      {agentOptions}
                    </div>
                  )}
                  <button
                    className={
                      this.state.selectedAgent != ""
                        ? styles.ok_btn
                        : `${styles.ok_btn_disable} ${styles.ok_btn}`
                    }
                    onClick={this.submitButton}
                  >
                    {!this.state.okloading && this.props.isMobile && <>Ok </>}
                    {!this.props.isMobile && <>Ok </>}
                    {this.state.okloading && (
                      <div className={styles.login_loader} />
                    )}
                  </button>
                  {this.state.site_id_error && (
                    <p className={styles.subagncy_label}>
                      Error! Parameters Missing
                    </p>
                  )}
                </>
              ) : (
                <>
                  {noagents && (
                    <p className={styles.subagncy_label}>
                      {text} {text.length > 0 ? `not found` : ``}
                    </p>
                  )}
                </>
              )
            ) : (
              <>
                {noagents && (
                  <p className={styles.subagncy_label}>
                    {text} {text.length > 0 ? `not found` : ``}
                  </p>
                )}
              </>
            )}
            {/* {(retrievedAgents.length > 0 || selectedChannel === "Agent") &&
            !loading ? (
              <>
                <h2 className={styles.agnc_head}>Agents:</h2>
                <input
                  type="text"
                  placeholder="Select an Agent"
                  className={styles.select_bx}
                  value={selectedAgentName}
                  onChange={this.handleAgentChange}
                  onClick={() => {
                    this.setState({ showDropdown: true });
                  }}
                />
                {showDropdown && (
                  <div className={styles.dropdown} ref={this.dropdownRef}>
                    {agentOptions}
                  </div>
                )}
                <button className={styles.ok_btn} onClick={this.submitButton}>
                  Ok
                </button>
              </>
            ) : (
              <>
                {noagents && (
                  <p className={styles.subagncy_label}>No Agents found</p>
                )}
              </>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Theme4);
